import React, { useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import { Head, Enterprises, Footer, WhatsApp } from '~/components'
import { useApi } from '~/siteApi'
import { Props as EnterpriseProps } from '~/components/Enterprise'
import * as st from '~/assets/styl/Enterprises.module.styl'
import { useLocation } from '@reach/router'

interface Context {
  enterprises: Array<never>
  title?: string
  slug?: string
}

const EnterprisesPage = ({ location, pageContext }: PageProps) => {
  const locationn = useLocation()

  const { title, slug } = pageContext as Context
  const search = locationn && locationn.search.slice(8)
  const [type, setType] = useState('')

  const { enterprises }: Context = useApi(
    pageContext,
    'page-enterprise' + (slug || type ? `?search=${slug || type}` : '')
  )

  const [enterprisesData, setEnterprisesData] =
    useState<Array<EnterpriseProps>>(enterprises)

  const getDataEnterprises = async (typee: string) => {
    const response = await fetch(
      `https://admin.aikonempreendimentos.com.br/api/page-enterprise?search=${typee}`
    )
    const data = await response.json()
    setEnterprisesData(data.enterprises)
  }

  useEffect(() => {
    if (locationn.search) {
      getDataEnterprises(search)
    } else {
      getDataEnterprises('')
    }
  }, [locationn.href])

  return (
    <>
      <Head
        location={location}
        title={`${title ? title + ' - ' : ''}Empreendimentos Aikon`}
      />
      <Enterprises
        title={title || 'Empreendimentos'}
        enterprises={enterprises}
        className={st.enterprisesPage}
        separateSold={!slug}
        location={location}
        enterprisesData={enterprisesData}
        setType={setType}
      />
      <Footer />
      <WhatsApp />
    </>
  )
}

export default EnterprisesPage
